@import "../variables-site/variables";
.site-qa {
    .wrapper {
        &__search {
            margin-bottom: 2.5rem;
            .form-search-qa {
                .group-search {
                    margin-bottom: 6rem;
                    max-width: 650px;
                    .top {
                        margin-bottom: 0.5rem;
                        display: grid;
                        grid-gap: 12px;
                        grid-template-columns: 1fr auto;
                        &__left {
                            .text {
                                font-size: $font-size-second;
                                color: $color-third;
                            }
                            .btn-tooltip {
                                position: relative;
                                padding: 0;
                                cursor: pointer;
                                color: $color-second;
                                border: none;
                                border-radius: 50%;
                            }
                        }
                        &__right {
                            display: grid;
                            grid-gap: 1.2rem 3rem;
                            grid-template-columns: repeat(2, auto);
                            .form-check {
                                padding: 0;
                            }
                        }
                    }
                }
                .control-group {
                    position: relative;
                    input {
                        display: block;
                        width: 100%;
                        padding: 0.8rem 6.8rem 0.8rem 1.2rem;
                        border-radius: 5px;
                        border: 1px solid $background-primary;
                        &:focus {
                            ~.search-history-list {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                    .btn-clear-words {
                        position: absolute;
                        top: 50%;
                        right: 4.5rem;
                        opacity: 0;
                        visibility: hidden;
                        transform: translateY(-50%);
                        @include transition();
                        &.active {
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    .btn-submit-search {
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        bottom: 2px;
                        width: 4rem;
                        color: $color-white;
                        border-radius: 5px;
                        background-color: $background-primary;
                        font-size: 24px!important;
                    }
                    .search-history-list {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                        position: absolute;
                        top: 102%;
                        left: 0;
                        right: 0;
                        height: 190px;
                        overflow-y: auto;
                        z-index: 99;
                        opacity: 0;
                        visibility: hidden;
                        border-radius: 5px;
                        border: 1px solid $background-primary;
                        background-color: #ffffff;
                        @include transition();
                        li {
                            padding: 1rem 3.5rem 1rem 1rem;
                            cursor: pointer;
                            @include transition();
                            ~li {
                                border-top: 2px dashed $background-four;
                            }
                            &:hover {
                                color: $color-hover;
                            }
                        }
                        .btn-clear-words-history {
                            position: absolute;
                            right: 6px;
                            margin: auto;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                .key-search-box {
                    @include grid-columns(1rem, 1fr 15rem);
                }
                .key-search {
                    display: inline-block;
                    position: relative;
                    margin-top: 0.5rem;
                    padding: 0.8rem 1rem;
                    border-radius: 5px;
                    background-color: $color-white;
                    &__name {
                        padding: 0.5rem 1.8rem;
                        color: $color-white;
                        border-radius: 3px;
                        background-color: $background-primary;
                    }
                    .btn-delete-filter-cate {
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        text-align: center;
                        border-radius: 50%;
                        color: $color-white;
                        font-size: 1.4rem;
                        cursor: pointer;
                        background-color: #000;
                        z-index: 10;
                        @include transition();
                        &:hover {
                            color: $color-primary;
                            background-color: $background-second;
                        }
                    }
                }
                .nav-box {
                    margin-bottom: 0;
                }
            }
        }
        &__result {
            .top {
                @include grid-columns(1.2rem, 1fr auto);
                &__right {
                    color: $color-third;
                }
            }
        }
        .list-qa {
            &__item {
                margin-bottom: 2.5rem;
                padding: 1.6rem;
                border-radius: 5px;
                border: 1px solid $color-second;
                background-color: #fff;
                &:last-child {
                    margin-bottom: 0;
                }
                .cate {
                    font-size: $font-size-second;
                    color: $color-second;
                    span {
                        position: relative;
                        ~span {
                            margin-left: 0.5rem;
                            &:before {
                                position: absolute;
                                content: ",";
                                left: -0.8rem;
                                bottom: 0;
                            }
                        }
                    }
                }
                .title {
                    position: relative;
                    margin-bottom: 1.5rem;
                    padding: 1.2rem 1.5rem 0.5rem 5rem;
                    border-bottom: solid 1rem #F2F2F2;
                    font-weight: 400;
                    font-size: 1.8rem;
                    border-radius: 3px;
                    background-color: $background-four;
                    border-radius: 3px;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    display: -webkit-box;
                    line-height: 1.5;
                    &:before {
                        position: absolute;
                        content: "";
                        top: 1.1rem;
                        left: 1.5rem;
                    }
                    &:after {
                        position: absolute;
                        content: "Q";
                        top: 0.2rem;
                        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
                        left: 1.3rem;
                        color: $color-second;
                        font-size: 3rem;
                        font-weight: 600;
                    }
                }
                .content {
                    @include grid-columns(1.2rem, 1fr auto);
                    overflow: hidden;
                    position: relative;
                    .more {
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                        background-color: rgb(255, 255, 255);
                        z-index: 2;
                        padding-left: 5px;
                    }
                    &__left {
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        position: relative;
                        line-height: 1.5;
                        font-size: 1.8rem;
                        img,
                        video {
                            max-height: 27px;
                            max-width: 40px !important;
                            object-fit: scale-down;
                            display: none !important;
                        }
                        p {
                            position: relative;
                            margin: 0;
                        }
                        span {
                            min-height: 27px;
                            margin: 0;
                            position: unset;
                            a {
                                cursor: default;
                                pointer-events: none;
                            }
                        }
                        &:before {
                            position: absolute;
                            content: "";
                            top: 0;
                            left: 1.5rem;
                        }
                        &:after {
                            position: absolute;
                            content: "A";
                            top: -1rem;
                            font-family: system-ui;
                            left: 1.3rem;
                            color: $color-second;
                            font-size: 3rem;
                            font-weight: 600;
                        }
                        a {
                            pointer-events: none;
                        }
                        @media (min-width: 1400px) {
                            padding: 0 0 0 5rem;
                        }
                        @media (min-width: 1200px) and (max-width: 1399px) {
                            padding: 0 0 0 5rem;
                        }
                        @media (max-width: 1399px) {
                            padding: 0 0 0 5rem;
                        }
                    }
                    &__right {
                        .btn-add-favorite {
                            position: relative;
                            display: inline-block;
                            @include box(24px);
                            line-height: 24px;
                            background-color: transparent;
                            color: $color-four;
                            &:before {
                                position: absolute;
                                content: "\f06f";
                                color: $color-four;
                                font-size: 2.4rem;
                                font-family: $font-icons;
                                top: 0;
                                left: 0;
                                @include transition();
                            }
                            &.active {
                                &:before {
                                    content: "\e838";
                                }
                            }
                        }
                    }
                }
                .tags {
                    padding: 2rem 1rem;
                    font-size: 1.5rem;
                    .tag {
                        padding: 0.1em 0.5em;
                        background-color: $background-second;
                        display: inline-block;
                        margin-top: 5px;
                        &.tag-highlight {
                            background-color: $background-primary;
                            color: white;
                        }
                    }
                }
            }
            .highlight {
                padding: 0;
                color: $color-white;
                background-color: $background-primary;
            }
        }
    }
    .custom-list-group {
        margin-top: 1rem;
        display: flex;
        justify-content: end;
        align-items: flex-start;
        .list-group-item {
            margin-right: 1rem;
        }
    }
}

.tooltip-help-qa {
    &[data-popper-placement^="top"] {
        .tooltip-arrow {
            &:before {
                right: 4px;
                border-top: 8px solid $background-primary;
                border-left: 8px solid transparent;
                border-right: 0;
            }
        }
    }
    .tooltip-inner {
        max-width: 470px;
        text-align: left;
        border-radius: 5px 5px 0 5px;
        background-color: $background-primary;
    }
}

.swal2-modal {
    min-height: 200px !important;
}

@media print {
    .sticky-top {
        position: inherit;
    }
    .wrapper__search,
    div.list-qa__item,
    .list-group.list-group-radio,
    .widget.widget-category,
    .widget.widget-keywords,
    .widget__body.widget-keywords__warp,
    .widget.widget-keywords.widget-keywords-history {
        break-inside: avoid;
    }
}